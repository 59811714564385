function hideSubscribeSuccessMessage() {
    $('#newsletter-success').hide();
}

function showSubscribeSuccessMessage() {
    $('#newsletter-success').show();
    $('#mail2').val('');
}

function subscribeNewsletterCaptcha() {
    const form = $('#subscribe-newsletter').get(0);
    if (form && form.isValid()) {
        if (form.requestSubmit) {
            form.requestSubmit();
        }
        else {
            form.trigger("submit");
        }
    }
}